import Rails from '@rails/ujs'
Rails.start()
global.Rails = Rails;
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("../plugins/jquery.zoomooz.min.js")

import jquery from 'jquery';
window.$ = window.jquery = jquery;

import "bootstrap"
import "popper.js"
import "@fortawesome/fontawesome-free/js/all.js"
import flatpickr from "flatpickr"
import jsQR from "jsqr"
import Panzoom from "@panzoom/panzoom"
import moment from "moment"

window.addEventListener("load", () => {
    document.querySelector('body').classList.add('loaded')
})

window.beachtimeGlobal = {}
window.beachtimeGlobal.appendAlert = (body, theType) => {
    let _theType = "alert-success"
    if (theType) {
        _theType = theType
    }

    $(".alert-container").append($(`
        <div class="alert ${_theType} border-2 align-items-center alert-dismissible fade show" role="alert">
            <div class="bg-success me-3 icon-item"><span class="fas fa-check-circle text-white fs-3"></span></div>
            <p class="mb-0 flex-1">${body}</p><button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close" style="font-size: 0.6em; top: 50%; transform: translateY(-50%);"></button>
        </div>
    `).delay(3000).fadeOut())
}

window.beachtimeGlobal.addFlatpickrToEl = () => {
    $('.flatpickr-time-only').flatpickr({
        noCalendar: true,
        enableTime: true,
        dateFormat: 'hK'
    })
}

$(document).on('turbolinks:load', () => {
    document.querySelector('body').classList.add('loaded')

    const map = document.getElementById('map')
    if (map) {
        const panzoom = Panzoom(map, {
            startScale: 1.5,
            startY: 10
        })

        $('.zoom-in').on('click', () => {
            panzoom.zoomIn()
            console.log('zoom out')
        })
    
        $('.zoom-out').on('click', () => {
            const scale = panzoom.getScale()
            if (scale >= 1) {
                panzoom.zoomOut()
            }
            console.log('zoom out', scale)
        })
    }

    let isScanned = false
    const $canvas = $('#canvas')
    if ($canvas.length > 0) {
        var video = document.createElement("video")
        var canvasElement = document.getElementById("canvas")
        var canvas = canvasElement.getContext("2d")
        var loadingMessage = document.getElementById("loadingMessage")
        var outputContainer = document.getElementById("output")
        var outputData = document.getElementById("outputData")

        function drawLine(begin, end, color) {
            canvas.beginPath()
            canvas.moveTo(begin.x, begin.y)
            canvas.lineTo(end.x, end.y)
            canvas.lineWidth = 4
            canvas.strokeStyle = color
            canvas.stroke()
        }

        navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then(function(stream) {
            video.srcObject = stream
            video.setAttribute("playsinline", true)
            video.play()
            requestAnimationFrame(tick)
        });

        function tick() {
            loadingMessage.innerText = "Loading video..."
            if (video.readyState === video.HAVE_ENOUGH_DATA) {
                loadingMessage.hidden = true
                canvasElement.hidden = false
                outputContainer.hidden = false

                canvasElement.height = video.videoHeight
                canvasElement.width = video.videoWidth
                canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height)
                var imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height)
                var code = jsQR(imageData.data, imageData.width, imageData.height, {
                    inversionAttempts: "dontInvert",
                });
                if (code) {
                    drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58")
                    drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58")
                    drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58")
                    drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58")
                    outputData.parentElement.hidden = false
                    outputData.innerText = code.data
                    
                    if (isScanned === false) {
                        $.ajax({
                            url: code.data,
                            method: 'get',
                            dataType: 'json'
                        })
                        .done((body) => {
                            const startsAtDate = moment(body.starts_at).utc()
                            const endsAtDate = moment(body.ends_at).utc()
                            console.log(body, startsAtDate, endsAtDate)
                            $('.check-in-confirmation-modal').find('.body').html(`<h5>Igloo #${body.spot_id}</h5><h5>${body.user.first_name} ${body.user.last_name}</h5><h5>${startsAtDate.format("ha")} until ${endsAtDate.format("ha")}</h5>`)
                            $('.check-in-confirmation-modal').modal('show')
                        })
                        .fail((xhr, error) => {
                            console.log(error)
                        })
                        isScanned = true
                    }
                } else {
                    outputData.parentElement.hidden = true
                }
            }
            requestAnimationFrame(tick)
        }
    }

    $('.check-in-confirmation-modal').on('hidden.bs.modal', () => {
        isScanned = false
    })

    const numbers = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine']

    $('.qr-wrapper').on('click', () => {
        $('.qr-modal').modal('show')
    })

    $('.add-to-chairs').on('click', () => {
        const newValue = parseInt($('input[name="reservation[chair_count]"]').val()) + 1

        if (newValue <= 4) {
            $('input[name="reservation[chair_count]"]').val(newValue)
            $('.chair-count').html(newValue - 2)

            const newPrice = parseInt($('.chair-price').data('price')) + 1000
            $('.chair-price').html(`$${(newPrice / 100).toFixed(2)}`)
            $('.chair-price').data('price', newPrice)
            $('.chair-price-input').val(newPrice)

            const newTotal = parseInt($('.total-price').data('price')) + 1000
            $('.total-price').html(`$${(newTotal / 100).toFixed(2)}`)
            $('.total-price').data('price', newTotal)
            $('.total-price-input').val(newTotal)
        }
    })

    $('.minus-from-chairs').on('click', () => {
        const newValue = parseInt($('input[name="reservation[chair_count]"]').val()) - 1

        if (newValue >= 2) {
            $('input[name="reservation[chair_count]"]').val(newValue)
            $('.chair-count').html(newValue - 2)

            const newPrice = parseInt($('.chair-price').data('price')) - 1000
            $('.chair-price').html(`$${(newPrice / 100).toFixed(2)}`)
            $('.chair-price').data('price', newPrice)
            $('.chair-price-input').val(newPrice)

            const newTotal = parseInt($('.total-price').data('price')) - 1000
            $('.total-price').html(`$${(newTotal / 100).toFixed(2)}`)
            $('.total-price').data('price', newTotal)
            $('.total-price-input').val(newTotal)
        }
    })

    $('.add-to-mats').on('click', () => {
        const newValue = parseInt($('input[name="reservation[mat_count]"]').val()) + 1
        $('input[name="reservation[mat_count]"]').val(newValue)
        $('.mat-count').html(newValue)

        const newPrice = parseInt($('.mat-price').data('price')) + 1000
        $('.mat-price').html(`$${(newPrice / 100).toFixed(2)}`)
        $('.mat-price').data('price', newPrice)
        $('.mat-price-input').val(newPrice)

        const newTotal = parseInt($('.total-price').data('price')) + 1000
        $('.total-price').html(`$${(newTotal / 100).toFixed(2)}`)
        $('.total-price').data('price', newTotal)
        $('.total-price-input').val(newTotal)
    })

    $('.minus-from-mats').on('click', () => {
        const newValue = parseInt($('input[name="reservation[mat_count]"]').val()) - 1
        if (newValue >= 0) {
            $('input[name="reservation[mat_count]"]').val(newValue)
            $('.mat-count').html(newValue)
            // $('.mat-count-word').html(numbers[newValue])

            const newPrice = parseInt($('.mat-price').data('price')) - 1000
            $('.mat-price').html(`$${(newPrice / 100).toFixed(2)}`)
            $('.mat-price').data('price', newPrice)
            $('.mat-price-input').val(newPrice)

            const newTotal = parseInt($('.total-price').data('price')) - 1000
            $('.total-price').html(`$${(newTotal / 100).toFixed(2)}`)
            $('.total-price').data('price', newTotal)
            $('.total-price-input').val(newTotal)
        }
    })

    const seatClicked = (e) => {
        console.log('seat clicked', e.delegateTarget.nodeName)

        if (!$(e.delegateTarget).hasClass('disabled')) {
            const spotId = parseInt($(e.delegateTarget).data('spot-id'))
            const newPrice = parseInt($(e.delegateTarget).data('price'))
            const title = $(e.delegateTarget).data('title')
            
            $('input[name="reservation[chair_count]"]').val(2)
            $('.variant-name').html(title)
            $('.base-price').data('price', newPrice)
            $('.base-price').html(`$${(newPrice / 100).toFixed(2)}`)
            $('.total-price').data('price', newPrice)
            $('.total-price').html(`$${(newPrice / 100).toFixed(2)}`)
            $('.total-price-input').val(newPrice)
            $('.base-price-input').val(newPrice)
            $('.base-price-type-input').val(title)

            $('.spot-id-input').val(spotId)
            $('.spot-number-modal').html(spotId)
            $('.seat-confirmation-modal').modal('show')
        }
    }

    $('.seat-first').on('click', (e) => seatClicked(e))
    $('.sandlot').on('click', (e) => seatClicked(e))
    $('.this-seat-first').on('click', (e) => seatClicked(e))
    $('.lounge-area').on('click', (e) => {
        $('.seat-confirmation-modal').find('.chairs-item').addClass('d-none')
        $('.seat-confirmation-modal').find('.chairs-rule').addClass('d-none')
        $('.seat-confirmation-modal').find('.additional-chair-item').addClass('d-none')
        $('.seat-confirmation-modal').find('.additional-chair-rule').addClass('d-none')
        $('.time-modal').html(`${$(e.target).data('starts-at')} until ${$(e.target).data('ends-at')}`)

        if ($(e.target).attr('data-description') !== '') {
            $('.modal-item-description').html($(e.target).attr('data-description'))
        }

        seatClicked(e)
        $('input[name="reservation[chair_count]"]').val(0)
        $('.starts_at_raw_input').val($(e.target).data('starts-at'))
        $('.ends_at_raw_input').val($(e.target).data('ends-at'))
        $('.base-price-type-input').val('Igloo')
        $('.spot-number-modal').html($(e.target).data('real-number'))
    })

    $('.btn-checkout').on('click', () => {
        $('.checkout-confirmation-modal').modal('show')
    })

    // $('#starts_at_raw').on('change', (e) => {
    //     $('#ends_at_raw').children.css("display", "block")
    //     $('.check-btn').attr('href', `/events/1/${$('.datepicker').data('date')}?starts_at_raw=${$('#starts_at_raw').val()}&ends_at_raw=${$('#ends_at_raw').val()}`)
    //     const value = parseInt(e.target.value.substring(0, 2))
        
    //     $.each($('#ends_at_raw').children('option'), (i, el) => {
    //         const _value = parseInt($(el).val().substring(0, 2))
            
    //         console.log(value, _value)
    //         if (_value < value) {
    //             $(el).css("display", "none")
    //         }
    //     })
    // })

    // $('#ends_at_raw').on('change', () => {
    //     $('.check-btn').attr('href', `/events/1/${$('.datepicker').data('date')}?starts_at_raw=${$('#starts_at_raw').val()}&ends_at_raw=${$('#ends_at_raw').val()}`)
    // })

    const _months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]

    if ($('.pickr-form').length > 0 && $('.flatpickr-calendar').length === 0) {
        const overrideDates = JSON.parse($('.pickr-form').attr('data-overrides'))

        let pickrForm = flatpickr('.datepicker', {
            inline: true,
            onChange: (_date, _dateString) => {
                $('.check-btn').attr('data-date', _dateString)
                $('.check-btn').attr('href', `/events/1/${_dateString}`)

                if (!$('.check-in-time').hasClass('is-invalid') && !$('.check-out-time').hasClass('is-invalid')) {
                    $('.check-btn').removeClass('disabled')
                }

                // $('.year-title').html(_date[0].getFullYear())
                // $('.month-title').html(_months[_date[0].getMonth()])
            },
            onMonthChange: (_date, _dateString, _instance) => {
                console.log('on month change', _date)
                $('.year-title').html(_instance.currentYear)
                $('.month-title').html(_months[_instance.currentMonth])
            },
            minDate: new Date(),
            disable: [
                (date) => {
                    const availableDays = JSON.parse($('.pickr-form').attr('data-available-dates'))
                    const availableMonths = JSON.parse($('.pickr-form').attr('data-available-months'))
                    return (!availableDays.includes(date.getDay()) || !availableMonths.includes(date.getMonth()))
                },
                ...overrideDates
            ],
            nextArrow: '',
            prevArrow: ''
        })

        $('.pickr-form-next').on('click', () => {
            pickrForm.changeMonth(1)
        })

        $('.pickr-form-prev').on('click', () => {
            pickrForm.changeMonth(-1)
        })
    }

    var $el = $("#very-specific-design")
    var elHeight = $el.outerHeight()
    var elWidth = $el.outerWidth()
    var $wrapper = $("#scaleable-wrapper")

    // $wrapper.resizable({
    //     resize: doResize
    // });

    function doResize(event, ui) {
        console.log("UI", ui)

        let scale 
        scale = Math.min(
            ui.size.width / elWidth,    
            ui.size.height / elHeight
        )
        
        if (scale >= 1) {
            $el.css({
                transform: "" + "scale(" + scale + ")"
            })
        }    
    }

    var starterData = { 
        size: {
            width: $wrapper.width(),
            height: $wrapper.height()
        }
    }
    doResize(null, starterData)

    $('.close-modal').on('click', (e) => $('.modal').modal('hide'))

    $('.check-in-time, .check-out-time').on('change', () => {
        if (moment($('.check-in-time').find('select').val(), 'ha').isAfter(moment($('.check-out-time').find('select').val(), 'ha'))) {
            $('.check-in-time').find('select').addClass('is-invalid')
            $('.check-out-time').find('select').addClass('is-invalid')
            $('.check-btn').addClass('disabled')
        }
        else {
            $('.check-in-time').find('select').removeClass('is-invalid')
            $('.check-out-time').find('select').removeClass('is-invalid')

            if ($('.check-btn')[0].hasAttribute('data-date')) {
                $('.check-btn').attr('href', `/events/1/${$('.check-btn').data('date')}?starts_at_raw=${$('#starts_at_raw').val()}&ends_at_raw=${$('#ends_at_raw').val()}`)
                $('.check-btn').removeClass('disabled')
            }
            else {
                $('.check-btn').addClass('disabled')
            }
        }
    })

    $('.checkout-form').on('submit', () => {
        $('.btn-checkout-form').html(`
            <div class="spinner-border spinner-border-sm" role="status" style="transform-origin: center;">
                <span class="sr-only"></span>
            </div>
        `)
    })

    $('.new-session-form').on('submit', () => {
        $('.btn-new-session').html(`
            <div class="spinner-border spinner-border-sm" role="status" style="transform-origin: center;">
                <span class="sr-only"></span>
            </div>
        `)
    })

    $('.new-registration-form').on('submit', () => {
        $('.btn-new-registration').html(`
            <div class="spinner-border spinner-border-sm" role="status" style="transform-origin: center;">
                <span class="sr-only"></span>
            </div>
        `)
    })

    $(document).on('submit', '.edit-user-form', () => {
        $('.btn-edit-user').html(`
            <div class="spinner-border spinner-border-sm" role="status" style="transform-origin: center;">
                <span class="sr-only"></span>
            </div>
        `)
    })

    $('#stripe-cs').on('click', () => {
        $('#stripe-cs').html(`
            <div class="spinner-border spinner-border-sm" role="status" style="transform-origin: center;">
                <span class="sr-only"></span>
            </div>
        `)
    })

    $(document).on('click', '.btn-remove-time-slot', (e) => {
        $(e.target).parents('.time-slot').remove()
    })

    window.beachtimeGlobal.addFlatpickrToEl()

    $('.flatpickr-date-string').flatpickr({
        dateFormat: 'F j, Y'
    })

    $('.blackout-modal').on('show.bs.modal', () => {
        $('.flatpickr-date-string').flatpickr({
            dateFormat: 'F j, Y'
        })
    })

    $(document).on('change', '.blackout-variant-input', (e) => {
        if (e.target.value === 'single_date_rule') {
            $('.price-input-group').addClass('d-none')
            $('.starts-at-group').addClass('d-none')
            $('.ends-at-group').addClass('d-none')
            $('.description-group').addClass('d-none')
        }
        else if (e.target.value === 'single_override_rule') {
            $('.price-input-group').removeClass('d-none')
            $('.starts-at-group').removeClass('d-none')
            $('.ends-at-group').removeClass('d-none')
            $('.description-group').removeClass('d-none')
        }
    })

    $(document).on('change', '.blackout-spot-type-input', (e) => {
        if (e.target.value === 'all_spots') {
            $('.spot-number-group').addClass('d-none')
            $('#blackout_real_number').removeClass('is-invalid')
            $('.btn-create-blackout').removeClass('disabled')
        }
        else {
            $('.spot-number-group').removeClass('d-none')
            $('#blackout_real_number').addClass('is-invalid')
            $('.btn-create-blackout').addClass('disabled')
        }
    })

    $(document).on('click', '.seat-group-outer', (e) => {
        const $currentTarget = $(e.currentTarget)
        const $target = $(`.seat-group[data-spot-real-number="${$currentTarget.attr('data-spot-real-number')}"][data-spot-variant="${$currentTarget.attr('data-spot-variant')}"]`)
        if (!$target.hasClass('disabled')) {
            $('.seat-confirmation-modal').modal('show');

            $.ajax({
                url: `/reservations/new`,
                type: 'get',
                dataType: 'script',
                data: {
                    variant: $target.attr('data-spot-variant'),
                    real_number: $target.attr('data-spot-real-number'),
                    date: $target.attr('data-spot-date')
                },
                success: function(data) {
                    console.log('success')
                },
                error: function() {
                    console.log('failure')
                }
            })
        }
    })

    $(document).on('click', '.seat-group', (e) => {
        const $target = $(e.currentTarget)
        if (!$target.hasClass('disabled')) {
            $('.seat-confirmation-modal').modal('show');

            $.ajax({
                url: `/reservations/new`,
                type: 'get',
                dataType: 'script',
                data: {
                    variant: $target.attr('data-spot-variant'),
                    real_number: $target.attr('data-spot-real-number'),
                    date: $target.attr('data-spot-date')
                },
                success: function(data) {
                    console.log('success')
                },
                error: function() {
                    console.log('failure')
                }
            })
        }
    })

    $(document).on('click', '.btn-add-to-cart', (e) => {
        $(e.currentTarget).html(`
            <div class="spinner-border spinner-border-sm" role="status" style="transform-origin: center;">
                <span class="sr-only"></span>
            </div>
        `)
    })

    $(document).on('change keyup', '#blackout_real_number', (e) => {
        // console.log('real number value', e.target.value, typeof e.target.value, parseInt(e.target.value))
        if (parseInt(e.target.value) >= 1 && parseInt(e.target.value) <= 38) {
            $('#blackout_real_number').removeClass('is-invalid')
            $('.btn-create-blackout').removeClass('disabled')
        }
        else {
            $('#blackout_real_number').addClass('is-invalid')
            $('.btn-create-blackout').addClass('disabled')
        }
    })
})
